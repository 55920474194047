import get from 'lodash.get';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Flag, Icon, Popup, Transition } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { testIXBRL } from 'http/erst';
import useUser from 'util/useUser';
import ColoredText from 'design/atoms/ColoredText';
import { downloadIXBRLDocument } from './downloadIXBRL';
import InlineLogoPicker from './InlineLogoPicker';
import Editor from './Editor';
import styles from './VirtualHTMLDocument.module.scss';

const InlineAssemblyDateForm = ({ children, renderAssemblyDateForm, disabled }) => {
    const [open, setOpen] = useState(false);

    return (
        <Popup
            basic
            on='click'
            open={open}
            disabled={disabled}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            position='top center'
            content={(
                <div style={{ width: '300px' }}>
                    {renderAssemblyDateForm({ onDatePicked: () => setOpen(false) })}
                </div>
            )}
            trigger={(
                <span
                    className={disabled ? undefined : styles.editableText}
                    onClick={() => setOpen(true)}
                    style={{
                        cursor: disabled ? undefined : 'pointer',
                    }}
                >
                    {children}
                </span>
            )}
        />
    );
};

const IXBRLTester = ({ ixbrl, getXBRLFiles, primoModelData }) => {
    const [working, setWorking] = useState(false);
    const [result, setResult] = useState(null);
    
    const performTest = async () => {
        if (result) {
            setResult(null)
            return;
        }

        setWorking(true);
        try {
            const supplementaryInstance = await getXBRLFiles('generateSupplementaryInstance', {
                reportingPeriod: primoModelData.periodPair.matchingPeriod,
            });
            const result = await testIXBRL(ixbrl, supplementaryInstance);
            setResult(result);
        } catch {
            toast.error('IXBRL kunne ikke testes...');
        } finally {
            setWorking(false);
        }
    };

    const icon = (
        <Icon
            link
            name={working ? 'spinner' : 'flask'}
            loading={working}
            color='grey'
            onClick={performTest}
            disabled={working}
        />
    );

    if (result) {
        return (
            <Popup
                basic
                position='bottom center'
                content={(
                    <div style={{ width: '500px', display: 'flex', flexDirection: 'column', gap: '0.5em' }}>
                        <div>
                            <strong>Statuskode:</strong> {result.statusCode}
                        </div>
                        <pre children={result.statusDetail} />
                        <Button fluid onClick={() => setResult(null)}>OK</Button>
                    </div>
                )}
                trigger={icon}
                open
            />
        );
    }

    return (
        <Popup
            basic
            position='top center'
            content='Test iXBRL'
            trigger={icon}
        />
    );
};

const PageOverlay = ({
    children,
    userMayEdit,
    overrideConfiguration,
    pageID,
    onOverwrite,
    disabled,
    isFirstPage,
    flowContext,
    updateFlowContext,
    selectedDocument,
    highlightXBRLValues,
    setHighlightXBRLValues,
    getXBRLFiles,
    primoModelData,
}) => {
    const [hovered, setHovered] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const user = useUser();

    const pageIsHidden = get(overrideConfiguration, `buildingBlockOverrides.${pageID}.hidden`) || false;

    const setHidden = hidden => {
        setMenuOpen(false);
        onOverwrite({
            editorID: pageID,
            namespace: 'buildingBlockOverrides',
            newData: { hidden },
        });
    };

    useEffect(() => {
        if (!hovered) setMenuOpen(false);
    }, [hovered]);

    const ellipsisMenu = (
        <Popup
            open={menuOpen}
            onOpen={() => setMenuOpen(true)}
            onClose={() => setMenuOpen(false)}
            on='click'
            position='left center'
            trigger={<Icon name='ellipsis horizontal' link size='large' />}
            content={(
                <div>
                    <ColoredText
                        underlined={false}
                        onClick={() => setHidden(!pageIsHidden)}
                        icon={pageIsHidden ? 'eye' : 'eye slash'}
                        content={pageIsHidden ? 'Vis side' : 'Skjul side'}
                        iconPosition='left'
                        link
                    />
                </div>
            )}
        />
    );

    const renderLanguageMenu = () => {
        if (!isFirstPage) {
            return null;
        }

        if (disabled) {
            return null;
        }

        const languageItems = [
            { code: 'da-DK', flag: 'dk', content: 'Dansk' },
            { code: 'en-GB', flag: 'gb', content: 'Engelsk' },
        ];

        const chosenLang = flowContext?.languageContext?.primaryLanguage || 'da-DK';
        const chosenLangItem = languageItems.find(item => item.code === chosenLang);

        const adminMenuItems = user.isAdmin && (
            <>
                <Popup
                    basic
                    position='top center'
                    content={`${highlightXBRLValues ? 'Deaktivér' : 'Aktivér'} fremhævning af XBRL-værdier`}
                    trigger={(
                        <Icon
                            link
                            name='code'
                            color={highlightXBRLValues ? 'blue' : 'grey'}
                            onClick={() => setHighlightXBRLValues(!highlightXBRLValues)}
                        />
                    )}
                />

                <IXBRLTester
                    ixbrl={selectedDocument.rawIXBRL}
                    getXBRLFiles={getXBRLFiles}
                    primoModelData={primoModelData}
                />
            </>
        );

        return (
            <div style={{ display: 'inline-block' }}>
                <div
                    style={{
                        background: 'rgba(1, 1, 1, 0.05)',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.5em',
                        padding: '1em',
                        paddingBottom: '0.75em',
                        paddingRight: '0.75em',
                        borderBottom: '1px solid lightgray',
                        borderRight: '1px solid lightgray',
                        borderBottomRightRadius: '4px',
                    }}
                >
                    {languageItems.map(langItem => {
                        const isActive = chosenLangItem === langItem; 
                        return (
                            <Flag
                                name={langItem.flag}
                                onClick={() => updateFlowContext({
                                    languageContext: { primaryLanguage: langItem.code },
                                })}
                                style={{
                                    opacity: isActive ? 1 : 0.5,
                                    cursor: isActive ? undefined : 'pointer',
                                    filter: isActive ? undefined : 'grayscale(50%)'
                                }}
                            />
                        );
                    })}
                    <div style={{ borderLeft: '1px solid lightgray', paddingLeft: '0.75em', display: 'flex', gap: '.5em' }}>
                        <Icon
                            onClick={() => downloadIXBRLDocument(selectedDocument, user)}
                            name='download'
                            color='grey'
                            link
                        />
                        {adminMenuItems}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div
            style={{ position: 'relative' }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {children}

            {isFirstPage && (
                <div style={{ position: 'absolute', top: '0', left: '0' }}>
                    {renderLanguageMenu()}
                </div>
            )}

            {!disabled && (
                <Transition visible={hovered} animation='fade down' duration={150}>
                    <div style={{ position: 'absolute', top: '0em', right: '0em' }}>
                        <div style={{ padding: '1em' }}>
                            {userMayEdit && ellipsisMenu}
                        </div>
                    </div>
                </Transition>
            )}
        </div>
    );
};

const VirtualHTMLDocument = ({
    virtualHTMLDocument,
    selectedDocument,
    paymentURL,
    productIsLocked,
    triggerReportRegeneration,
    onOverwrite,
    overrideConfiguration,
    renderAssemblyDateForm,
    flowContext,
    updateFlowContext,
    getXBRLFiles,
    primoModelData,
}) => {
    const history = useHistory();
    const user = useUser();
    const [activeEditorID, setActiveEditorID] = useState(null);
    const [highlightXBRLValues, setHighlightXBRLValues] = useState(false);

    const xbrlContexts = useMemo(() => {
        if (!user.isAdmin()) {
            return [];
        }

        const parser = new DOMParser();
        const doc = parser.parseFromString(selectedDocument.rawIXBRL, 'application/xhtml+xml');

        return [...doc.getElementsByTagName('xbrli:context')].map(contextNode => {
            const parsedContext = {
                id: contextNode.getAttribute('id'),
                instantDate: null,
                startDate: null,
                endDate: null,
                dimension: null,
                typedMember: null,
                explicitMemberTag: null,
            };

            const [instantNode] = contextNode.getElementsByTagName('xbrli:instant');
            if (instantNode) {
                parsedContext.instantDate = instantNode.textContent;
            }

            const [startDateNode] = contextNode.getElementsByTagName('xbrli:startDate');
            const [endDateNode] = contextNode.getElementsByTagName('xbrli:endDate');
            if (startDateNode && endDateNode) {
                parsedContext.startDate = startDateNode.textContent;
                parsedContext.endDate = endDateNode.textContent;
            }

            const [typedMemberNode] = contextNode.getElementsByTagName('xbrldi:typedMember');
            const [explicitMemberNode] = contextNode.getElementsByTagName('xbrldi:explicitMember');
            if (typedMemberNode) {
                const firstChild = typedMemberNode.children[0];
                parsedContext.dimension = typedMemberNode.getAttribute('dimension');
                parsedContext.typedMember = {
                    tag: firstChild.tagName,
                    identifier: firstChild.textContent,
                };
            } else if (explicitMemberNode) {
                parsedContext.dimension = explicitMemberNode.getAttribute('dimension');
                parsedContext.explicitMemberTag = explicitMemberNode.textContent;
            }

            return parsedContext;
        });
    }, [user, selectedDocument]);

    const addClassName = (props, className) => {
        if (!props['class']) {
            props['class'] = className;
        } else {
            props['class'] += ` ${className}`;
        }
    };

    const hasClassName = (ele, ...classNamesToCheck) => {
        const elementClassName = ele['class'] || '';
        return (
            elementClassName
            .split(' ')
            .map(x => x.trim())
            .filter(x => x)
            .some(c => classNamesToCheck.includes(c))
        );
    };

    let hasRenderedPageBefore = false;

    const renderVirtualHTMLElement = virtualHTMLElement => {
        const {
            tag,
            style,
            children,
            metadata = {},
            ...other
        } = virtualHTMLElement;

        const props = {
            style,
            ...other,
        };

        const shouldHighlightAsXBRLValue = highlightXBRLValues && tag.startsWith('ix:');
        if (shouldHighlightAsXBRLValue) {
            addClassName(props, styles.xbrlValue);
        }

        const elementIsPage = hasClassName(props, 'page');
        if (elementIsPage && !productIsLocked) {
            addClassName(props, styles.editablePage);
        }

        const userMayEdit = (user.isAdmin() || user.isAccountant()) && !highlightXBRLValues;
        const elementCanBeEdited = 'editorKind' in metadata;
        
        let elementHasBeenEdited = false;

        if (elementCanBeEdited && userMayEdit && !productIsLocked) {
            props.id = metadata.editorID;
            props.onClick = () => setActiveEditorID(metadata.editorID);

            const documentComponentOverrides = (overrideConfiguration?.documentComponentOverrides || {})[metadata.editorID] || {};
            const buildingBlockOverrides = (overrideConfiguration?.buildingBlockOverrides || {})[metadata.editorID] || {};
            const hasActiveDocumentComponentOverrides = Object.values(documentComponentOverrides).some(value => !!value);
            const hasActiveBuildingBlockOverrides = Object.values(buildingBlockOverrides).some(value => !!value);

            elementHasBeenEdited = hasActiveDocumentComponentOverrides || hasActiveBuildingBlockOverrides;

            addClassName(props, elementHasBeenEdited ? styles.editedText : styles.editableText);
        }

        if (hasClassName(virtualHTMLElement, 'purchaseLink')) {
            props.onClick = () => history.push(paymentURL);
        }

        const convertedChildren = children.map(ele => {
            if (typeof ele === 'string') {
                return ele;
            }
            return renderVirtualHTMLElement(ele);
        });

        let element;
        if (convertedChildren.length === 0) {
            element = React.createElement(tag, props);
        } else {
            element = React.createElement(tag, props, convertedChildren);
        }

        if (shouldHighlightAsXBRLValue) {
            const context = xbrlContexts.find(context => context.id === virtualHTMLElement.contextRef);
            
            const info = [
                { title: 'Tag', data: virtualHTMLElement.name },
            ];

            if (context.instantDate) {
                info.push({ title: 'Tidspunkt', data: context.instantDate });
            }
            
            if (context.startDate && context.endDate) {
                info.push({ title: 'Periode', data: `${context.startDate} til ${context.endDate}` });
            }

            if (context.dimension) {
                info.push({
                    title: 'Dimension',
                    data: context.dimension,
                });
            }

            if (context.typedMember) {
                info.push({
                    title: 'Identifikator',
                    data: `${context.typedMember.tag} ${context.typedMember.identifier}`,
                });
            }

            if (context.explicitMemberTag) {
                info.push({ title: 'Member', data: context.explicitMemberTag });
            }

            return (
                <Popup
                    basic
                    flowing
                    position='top center'
                    trigger={element}
                    content={(
                        <div style={{ display: 'flex', gap: '.5em', flexDirection: 'column' }}>
                            {info.map(({ title, data }) => {
                                return (
                                    <div>
                                        <strong>{title}</strong>
                                        <br />
                                        {data}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                />
            );
        }

        if (metadata.editorID === activeEditorID) {
            const buildingBlockOverrides = (overrideConfiguration?.buildingBlockOverrides || {})[metadata.editorID];
            const documentComponentOverrides = (overrideConfiguration?.documentComponentOverrides || {})[metadata.editorID];

            return (
                <Editor
                    children={element}
                    metadata={metadata}
                    onSave={onOverwrite}
                    onClose={() => setActiveEditorID(null)}
                    hasBeenEdited={elementHasBeenEdited}
                    buildingBlockOverrides={buildingBlockOverrides}
                    documentComponentOverrides={documentComponentOverrides}
                />
            );
        }

        if (elementIsPage) {
            const isFirstPage = !hasRenderedPageBefore;
            hasRenderedPageBefore = true;
            return (
                <PageOverlay
                    userMayEdit={userMayEdit}
                    overrideConfiguration={overrideConfiguration}
                    onOverwrite={onOverwrite}
                    pageID={metadata.editorID}
                    children={element}
                    disabled={productIsLocked}
                    isFirstPage={isFirstPage}
                    flowContext={flowContext}
                    updateFlowContext={updateFlowContext}
                    selectedDocument={selectedDocument}
                    highlightXBRLValues={highlightXBRLValues}
                    setHighlightXBRLValues={setHighlightXBRLValues}
                    getXBRLFiles={getXBRLFiles}
                    primoModelData={primoModelData}
                />
            );
        }

        const isDateOfGeneralMeeting = virtualHTMLElement?.name?.endsWith('DateOfGeneralMeeting');
        if (isDateOfGeneralMeeting && userMayEdit) {
            return (
                <InlineAssemblyDateForm
                    renderAssemblyDateForm={renderAssemblyDateForm}
                    children={element}
                    disabled={productIsLocked}
                />
            );
        }

        if (hasClassName(virtualHTMLElement, 'emptyLogo', 'reportLogo')) {
            const isEmpty = hasClassName(virtualHTMLElement, 'emptyLogo');
            
            return (
                <InlineLogoPicker
                    isLocked={productIsLocked}
                    isEmpty={isEmpty}
                    trigger={element}
                    onLogoPicked={triggerReportRegeneration}
                />
            );
        }
        
        return element;
    };

    return (
        <>
            <div style={{ all: 'initial' }} id='documentWrapper'>
                {virtualHTMLDocument && renderVirtualHTMLElement(virtualHTMLDocument)}
            </div>
        </>
    );
};

export default VirtualHTMLDocument;