import get from 'lodash.get';
import set from 'lodash.set';
import React, { useEffect, useMemo, useState } from 'react';
import { Transition } from 'semantic-ui-react';
import * as fileStorage from 'http/file-storage';
import { TAX_REPORTED_TAG } from 'util/FactMapUtil';
import { useRootUser } from 'util/useUser';
import IXBRLYearReportViewer from './IXBRLYearReportViewer';
import IXBRLEsgReportViewer from './IXBRLEsgReportViewer';
import VirtualHTMLDocument from './VirtualHTMLDocument';
import { AssemblyDateForm } from '../AssemblyDateModal';
import collectSystemDocuments from '../collectSystemDocuments';

const EXECUTION_STATUSES = {
    NOT_STARTED: 'NOT_STARTED',
    EXECUTING: 'EXECUTING',
    DONE: 'DONE',
};

const IXBRLViewer = ({ value, regenerateDocument, factsBeingUpdated, productMetadata, id, paymentURL, executionError, values, onChange, hasPayed, ...props}) => {
    const [selectedDocumentIndex, setSelectedDocumentIndex] = useState(0);
    const [virtualHTMLDocument, setVirtualHTMLDocument] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [yearReportDataExecutionStatus, setYearReportDataExecutionStatus] = useState(EXECUTION_STATUSES.NOT_STARTED);
    const rootUser = useRootUser();

    const productIsLocked = values?.LOCK?.value?.isLocked || false;

    const documentGeneratorProductFact = productMetadata.facts[id];
    const yearReportDataTriggerFactID = documentGeneratorProductFact.options.yearReportTriggerFact;
    
    useEffect(() => {
        if (productIsLocked) {
            setYearReportDataExecutionStatus(EXECUTION_STATUSES.DONE);
            return;
        }

        switch (yearReportDataExecutionStatus) {
            case EXECUTION_STATUSES.NOT_STARTED: {
                if (factsBeingUpdated.has(yearReportDataTriggerFactID)) {
                    setYearReportDataExecutionStatus(EXECUTION_STATUSES.EXECUTING);
                }
                break;
            }

            case EXECUTION_STATUSES.EXECUTING: {
                if (!factsBeingUpdated.has(yearReportDataTriggerFactID)) {
                    setYearReportDataExecutionStatus(EXECUTION_STATUSES.DONE);
                }
                break;
            }

            default: break; // no-op
        }
    }, [factsBeingUpdated, yearReportDataExecutionStatus, yearReportDataTriggerFactID, productIsLocked]);

    const documents = useMemo(() => {
        if (!value) return [];

        return collectSystemDocuments(rootUser, value, values);
    }, [rootUser, value, values]);

    const selectedDocument = documents[selectedDocumentIndex];
    const inputHash = value?.inputHash;

    useEffect(() => {
        if (executionError) {
            setError(true);
            setLoading(false);
            return;
        }

        if (yearReportDataExecutionStatus !== EXECUTION_STATUSES.DONE) return;
        if (!selectedDocument) return;
        
        setError(false);

        fileStorage.getFileData(selectedDocument.virtualHTMLDocumentFileID)
            .then(async (file) => {
                const bytes = Uint8Array.from(file.data.data);
                const rawJSON = new TextDecoder().decode(bytes);
                const virtualHTMLDocument = JSON.parse(rawJSON);
                setVirtualHTMLDocument(virtualHTMLDocument);
            })
            .catch(e => {
                console.error(e);
                setError(true);
            })
            .finally(() => setLoading(false));

    }, [inputHash, factsBeingUpdated, yearReportDataExecutionStatus, executionError, selectedDocument]);

    const triggerReportRegeneration = () => {
        if (productIsLocked) return;

        setYearReportDataExecutionStatus(EXECUTION_STATUSES.NOT_STARTED);
        setVirtualHTMLDocument(null);
        setLoading(true);
        regenerateDocument();
    };

    const contentIsReady = !loading && !error && virtualHTMLDocument;

    const renderAssemblyDateForm = ({ onDatePicked }) => (
        <AssemblyDateForm
            disabled={props.isLocked || props.updating}
            updating={props.updating}
            assemblyDate={props.assemblyDate}
            onChange={newDate => {
                props.setAssemblyDate(newDate);
                onDatePicked();
            }}
        />
    );

    const documentWrapper = (
        <Transition visible={contentIsReady} duration={500} animation='fade'>
            {/* document wrapped in div to make the transition work properly */}
            <div>
                <VirtualHTMLDocument
                    virtualHTMLDocument={virtualHTMLDocument}
                    productIsLocked={productIsLocked}
                    paymentURL={paymentURL}
                    renderAssemblyDateForm={renderAssemblyDateForm}
                    triggerReportRegeneration={triggerReportRegeneration}
                    getXBRLFiles={props.getXBRLFiles}
                    selectedDocument={selectedDocument}
                    overrideConfiguration={get(props.flowContext, selectedDocument?.overrideConfigurationFlowContextPropertyPath)}
                    flowContext={props.flowContext}
                    updateFlowContext={props.updateFlowContext}
                    primoModelData={props.primoModelData}
                    onOverwrite={overwriteRequest => {
                        const { flowContext, updateFlowContext } = props;
                        
                        const overrideConfiguration = get(flowContext, selectedDocument.overrideConfigurationFlowContextPropertyPath) || {};
                        
                        const { editorID, namespace, newData, shouldDelete } = overwriteRequest;
                        if (shouldDelete) {
                            delete overrideConfiguration[namespace][editorID];
                        } else if (newData) {
                            set(overrideConfiguration, `${namespace}.${editorID}`, newData);
                        } else {
                            throw new Error('Bad overwrite request: no data or delete action specified');
                        }

                        updateFlowContext({ [selectedDocument.overrideConfigurationFlowContextPropertyPath]: overrideConfiguration });
                    }}
                />
            </div>
        </Transition>
    );

    const updatedAtTimestamp = new Date(value?.updatedAt).toLocaleString('da-DK', {
        day: 'numeric', 
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    });

    const taxReportedProductFact = Object.values(productMetadata.facts).find(fact => {
        return fact.tag === TAX_REPORTED_TAG;
    });

    if (productMetadata.kind === 'ESG') {
        return (
            <IXBRLEsgReportViewer
                loading={loading}
                error={error}
                productIsLocked={productIsLocked}
                contentIsReady={contentIsReady}
                updatedAtTimestamp={updatedAtTimestamp}
                value={value}
                onChange={onChange}
                taxReportedProductFact={taxReportedProductFact}
                hasPayed={hasPayed}
                documentWrapper={documentWrapper}
                triggerReportRegeneration={triggerReportRegeneration}
            />
        );
    }

    return (
        <IXBRLYearReportViewer
            {...props}
            docgenID={id}
            loading={loading}
            selectedDocument={selectedDocument}
            documents={documents}
            selectedDocumentIndex={selectedDocumentIndex}
            setSelectedDocumentIndex={setSelectedDocumentIndex}
            contentIsReady={contentIsReady}
            documentWrapper={documentWrapper}
            productMetadata={productMetadata}
            factsBeingUpdated={factsBeingUpdated}
            hasPayed={hasPayed}
            values={values}
            value={value}
            paymentURL={paymentURL}
        />
    );
};

export default IXBRLViewer;
