import React from 'react';
import { Transition, Checkbox, Message, Icon } from 'semantic-ui-react';
import { useCreditsContext } from 'design/molecules/CreditsProvider';
import { ShoppingCartSegment } from '../shared/ShoppingCart';
import { useRootUser } from 'util/useUser';

const CreditsConfirmAccountantRole = ({ role, hasConfirmedRole, onConfirmRole }) => {
    const { loading } = useCreditsContext();
    const user = useRootUser();
    
    const getRoleDisplay = () => {
        if (user.roles) {
            if (user.roles.includes('bookkeeper')) {
                return 'Rapport';
            }
            if (user.roles.includes('accountant')) {
                return 'Rapport & erkæring';
            }
        }
        return role;
    };
    
    const displayRole = getRoleDisplay();
    
    if (user.roleLocked) {
        return null;
    }

    return (
        <Transition visible={!loading}>
            <ShoppingCartSegment title='Bekræft rolle' icon='linkify'>
                {user.roleLocked ? (
                    <Message info>
                        <Icon name="lock" />
                        Din rolle er låst til <strong>{displayRole}</strong> og kan ikke ændres.
                    </Message>
                ) : (
                    <>
                        <p>
                            <Checkbox 
                                label={{ children: <span>Jeg bekræfter herved at jeg har valgt rollen <strong>{displayRole}</strong>.</span> }}
                                checked={hasConfirmedRole} 
                                onChange={(e, data) => onConfirmRole(data.checked)}
                            />
                        </p>
                        
                        <p>
                            Rollen fastsættes ved dette køb,
                            og vil herefter ikke kunne ændres.
                        </p>
                    </>
                )}
            </ShoppingCartSegment>
        </Transition>
    );
};

export default CreditsConfirmAccountantRole;