import React, { Component } from 'react'
import { Message, Form, Input, Button, Modal, Dropdown } from 'semantic-ui-react';
import styles from './connectors.module.scss';
import { ZENEGY_CALLBACK_URL } from 'config/config';
import { getAccessToken, hasRefreshToken } from 'util/token';
import TokenUser from 'util/TokenUser';
import { refreshTokens } from 'network/fetch/refreshTokens'
import { getClientInfo } from 'http/productEngine';
import { getImpersonatedID, beginImpersonation } from 'util/impersonation';
import { unsetParam } from '../../QueryParams';

class ZenegyConnector extends Component {
    state = { 
        failed: false, 
        working: false, 
        companies: [], 
        selectedCompany: null, 
        selectedCompanyName: null,
        showModal: false  
    };

    componentDidMount() {
        // Check if redirected with companies in URL
        const params = new URLSearchParams(window.location.search);
        const zenegyregistration = params.get("zenegyregistration");

        if (zenegyregistration=="success") {
            console.log("zenegy registration successfull");
            unsetParam("zenegyregistration");
            
            this.props.onRegister();
        }
    } 

    displayErrorMessage = () => {
        const { failed } = this.state;
        if (failed) {
            return <Message negative>
                <Message.Header>Der opstod en fejl</Message.Header>
                <p>Kontroller oplysningerne</p>
            </Message>;
        }
    };

    registerZenegy = async () => {
        this.setState({ working: true });
    
        try {
            console.log("Calling registerZenegy");
            const client = "e8bf7623eeca4427ba3563ffa82303fb";
            const callbackurl = ZENEGY_CALLBACK_URL;
            window.sessionStorage.afterZenegyIntegrationRedirectPath = window.location.pathname;
    
            // Encode userID and return URL in state object
            console.log("Getting user");
            const user = new TokenUser(getAccessToken());
            
            let userID=user.id; // use this ID if a company
            if(getImpersonatedID()){
                userID=getImpersonatedID(); // use this id if is client
            }

            const stateObj = {
                userID: userID,
                returnUrl: window.location.href // Current page URL
            };
            const encodedState = encodeURIComponent(JSON.stringify(stateObj));
            const ZENEGY_LOGIN_PAGE = `https://auth.beta.zalary.com/auth/authorize?client_id=${client}&response_type=code&redirect_uri=${encodeURIComponent(callbackurl)}&state=${encodedState}`;

            window.location.href = ZENEGY_LOGIN_PAGE;
            

            this.props.onRegister();
        } catch {
            this.setState({ failed: true, working: false });
        }
    };

    render = () => {
        return (
            <div>
                <div className={styles.actionsContainer}>
                    <Button
                        onClick={this.registerZenegy}
                        disabled={this.state.working}
                        loading={this.state.working}
                        content='Forbind'
                        primary
                    />
                </div>
                {this.displayErrorMessage()}
            </div>
        );
    };
}

export default ZenegyConnector;